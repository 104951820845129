import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled, alpha } from "@mui/material/styles";
import { movies_list } from "../actions/movies/actions";
import { useDispatch, useSelector } from "react-redux";
import TrendingMovies from "../api/movies/trending";
import Header from "../header";
import Footer from "../footer";
import { IMG_300, IMG_BASE_URL } from "../constants";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import "./style.css";
import { blue, green, orange, yellow } from "@mui/material/colors";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const Trending = ({ type }) => {
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState("");
  const [contentType, setType] = React.useState('all');
  const [timeWindow, setTimeWindow] = React.useState("day");
  const movies = useSelector((state) => state.movies.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    setType(type);
  }, [type])

  React.useEffect(() => {
    async function fetchData() {
      const payload = {
        time_window: timeWindow,
        page,
        type: contentType,
      };
      const response = await TrendingMovies(payload);
      dispatch(movies_list(response));
    }
    fetchData();
  }, [dispatch, page, timeWindow, contentType]);

  const onPaginationChange = (event, page) => {
    setPage(page);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const onSearch = (e) => {
    setSearch(e?.target?.value);
  };

  const onKeyDownHandler = (e) => {
    if (e?.key === 'Enter') {
      navigate(`/search?s=${e?.target?.value}&type=${type === 'all' ? 'multi' : type}`);
    }
  }

  const onHandleClick = () => {
    if (search) {
      navigate(`/search?s=${search}&type=${contentType === 'all' ? 'multi' : contentType}`);
    }
  };

  return (
    <>
      <Header />
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{
            margin: "10px 16px 8px 16px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
            }}
          >
            {location?.pathname === '/trending' && (
              <FormControl fullWidth sx={{ width: "150px" }}>
                <Select
                  labelId="type-label"
                  id="type-select"
                  value={contentType}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={(e) => setType(e?.target?.value)}
                  sx={{
                    color: "whitesmoke",
                    background: "#575d64",
                    "& .MuiSelect-select": { padding: 1 },
                    "& svg": { color: "whitesmoke" },
                  }}
                  className="typeDropdown"
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="movie">Movie</MenuItem>
                  <MenuItem value="tv">TV</MenuItem>
                </Select>
              </FormControl>
            )}
            <FormControl fullWidth sx={{ width: "150px", marginLeft: 1 }}>
              <Select
                labelId="time-window-label"
                id="time-window-select"
                value={timeWindow}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                onChange={(e) => setTimeWindow(e?.target?.value)}
                sx={{
                  color: "whitesmoke",
                  background: "#575d64",
                  "& .MuiSelect-select": { padding: 1 },
                  "& svg": { color: "whitesmoke" },
                }}
                className="timeDropdown"
              >
                <MenuItem value="day">Day</MenuItem>
                <MenuItem value="week">Week</MenuItem>
              </Select>
            </FormControl>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                onChange={onSearch}
                onKeyDown={onKeyDownHandler}
              />
            </Search>
            <Button
              variant="contained"
              sx={{
                marginLeft: 1,
                color: "whitesmoke",
                backgroundColor: "rgba(255, 255, 255, .1)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, .1)",
                },
              }}
              onClick={onHandleClick}
            >
              Search
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "stretch",
            justifyContent: "center",
            flexWrap: "wrap",
            flex: 1,
          }}
        >
          {movies?.results?.length
            ? movies?.results?.map((item, index) => (
              <Link to={`/${item?.media_type}/${item?.id}`} key={index} className="links">
                <Card
                  sx={{
                    minWidth: 220,
                    maxWidth: 220,
                    margin: 1,
                    boxShadow: "none",
                    position: "relative",
                  }}
                >
                  <CardMedia
                    sx={{ height: 320 }}
                    image={`${IMG_BASE_URL}/${IMG_300}/${item?.poster_path}`}
                    title={item.name}
                  />
                  <span
                    style={{
                      background: "rgb(0 0 0 / 35%)",
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: 0,
                    }}
                  />
                  <CardContent
                    sx={{
                      width: "100%",
                      padding: "0px !important",
                      position: "absolute",
                      bottom: 0,
                      color: "whitesmoke",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        textAlign: "left",
                        fontSize: 17,
                        fontWeight: "500",
                        padding: 1,
                      }}
                    >
                      {item?.title}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "0px 8px 8px 8px",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{
                          textAlign: "left",
                          fontSize: 13,
                          fontWeight: "500",
                          background: blue[600],
                          padding: "6px 8px 4px 8px",
                          borderRadius: 5,
                          width: "auto",
                        }}
                      >
                        {item?.release_date
                          ? item?.release_date?.split("-")[0]
                          : item?.first_air_date?.split("-")[0]}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          textAlign: "left",
                          fontSize: 13,
                          fontWeight: "500",
                          background: yellow[900],
                          padding: "6px 8px 4px 8px",
                          borderRadius: 5,
                          width: "auto",
                        }}
                      >
                        {`${item?.vote_average?.toFixed(1)}/10`}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          textAlign: "left",
                          fontSize: 13,
                          fontWeight: "500",
                          background:
                            item?.media_type === "tv"
                              ? orange[500]
                              : green[500],
                          padding: "6px 8px 4px 8px",
                          borderRadius: 5,
                          width: "auto",
                        }}
                      >
                        {`${item?.media_type?.toUpperCase()}`}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Link>
            ))
            : [...new Array(20)]?.map((_, index) => (
              <Card
                sx={{
                  minWidth: 220,
                  maxWidth: 220,
                  margin: 1,
                  boxShadow: "none",
                  position: "relative",
                }}
                key={index}
              >
                <Skeleton
                  sx={{ bgcolor: "grey.500" }}
                  variant="rectangular"
                  width={220}
                  height={320}
                  key={index}
                />
              </Card>
            ))}
        </Grid>
      </Grid>
      {movies?.results?.length ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Pagination
            count={movies?.total_pages}
            page={page}
            variant="outlined"
            shape="rounded"
            sx={{
              "& button": {
                background: "rgba(255, 255, 255, .1)",
                color: "whitesmoke",
              },
              "& button.Mui-selected": {
                background: "whitesmoke",
                color: "#000",
              },
              "& div": {
                color: "#fff",
              },
            }}
            onChange={onPaginationChange}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Skeleton
            sx={{ bgcolor: "grey.500" }}
            variant="rectangular"
            width="40%"
          />
        </Box>
      )}
      <Footer />
    </>
  );
};

export default Trending;
