import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputBase from '@mui/material/InputBase';
import { styled, alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const SearchBar = ({ type, setType }) => {
  const [search, setSearch] = React.useState('');

  const navigate = useNavigate();

  const onSearch = (e) => {
    setSearch(e?.target?.value)
  }

  const onKeyDownHandler = (e) => {
    if (e?.key === 'Enter') {
      navigate(`/search?s=${e?.target?.value}&type=${type === 'all' ? 'multi' : type}`);
    }
  }

  const onHandleClick = () => {
    if (search) {
      navigate(`/search?s=${search}&type=${type === 'all' ? 'multi' : type}`);
    }
  }

  return (
    <Grid item xs={12} sx={{ margin: '10px 16px 8px 16px', display: 'flex', justifyContent: 'flex-end' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
        <FormControl fullWidth sx={{ width: "150px" }}>
          <Select
            labelId="type-label"
            id="type-select"
            value={type}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            onChange={(e) => setType(e?.target?.value)}
            sx={{
              color: "whitesmoke",
              background: "#575d64",
              "& .MuiSelect-select": { padding: 1 },
              "& svg": { color: "whitesmoke" },
            }}
            className="typeDropdown"
          >
            <MenuItem value="movie">Movie</MenuItem>
            <MenuItem value="tv">TV</MenuItem>
          </Select>
        </FormControl>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
            onChange={onSearch}
            onKeyDown={onKeyDownHandler}
          />
        </Search>
        <Button
          variant='contained'
          sx={{
            marginLeft: 1,
            color: 'whitesmoke',
            backgroundColor: 'rgba(255, 255, 255, .1)',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, .1)',
            },
          }}
          onClick={onHandleClick}
        >Search</Button>
      </Box>
    </Grid>
  )
}

export default SearchBar;
