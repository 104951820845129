import {
    GET_GENRES
} from "../../actions/movies/types";

const initialState = {
    fetching: 'pending',
    data: [],
}

const GenresReducer = (state = initialState, {
    type,
    payload
}) => {
    switch (type) {
        case GET_GENRES: {
            return {
                ...state,
                fetching: 'completed',
                data: payload
            }
        }

        default:
            return state;
    }
}

export default GenresReducer;