import {
  GET_MOVIE_DETAILS,
} from "../../actions/movies/types";

const initialState = {
  fetching: 'pending',
  data: [],
}

const MovieDetailsReducer = (state = initialState, {
  type,
  payload
}) => {
  switch (type) {
    case GET_MOVIE_DETAILS: {
      return {
        ...state,
        fetching: 'completed',
        data: payload
      }
    }

    default:
      return state;
  }
}

export default MovieDetailsReducer;