import React from 'react'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { movies_list } from '../actions/movies/actions';
import { useDispatch, useSelector } from 'react-redux';
import DiscoverMovies from '../api/movies/movies';
import Header from '../header';
import Footer from '../footer';
import { IMG_300, IMG_BASE_URL } from '../constants';
import { Link, useParams } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import Skeleton from '@mui/material/Skeleton';
import './style.css'
import { blue, yellow } from '@mui/material/colors';
import SearchBar from '../components/searchbar';



const Home = () => {
  const [page, setPage] = React.useState(1);
  const [type, setType] = React.useState('movie');
  const movies = useSelector(state => state.movies.data);
  const dispatch = useDispatch();
  const { genre } = useParams()

  React.useEffect(() => {
    async function fetchData() {
      const payload = {
        page: page,
        genre: genre ?? '',
      }
      const response = await DiscoverMovies(payload);
      dispatch(movies_list(response));
    }
    fetchData();

  }, [dispatch, page, genre]);

  const onPaginationChange = (event, page) => {
    setPage(page)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <>
      <Header />
      <Grid container spacing={2}>
        <SearchBar
          type={type}
          setType={setType}
        />
        <Grid item xs={12} sx={{
          display: 'flex',
          alignItems: 'stretch',
          justifyContent: 'center',
          flexWrap: 'wrap',
          flex: 1,
        }}>
          {movies?.results?.length ? movies?.results?.map((item, index) => (
            <Link to={`/${type}/${item?.id}`} key={index} className='links'>
              <Card sx={{ minWidth: 220, maxWidth: 220, margin: 1, boxShadow: 'none', position: 'relative' }} >
                <CardMedia
                  sx={{ height: 320 }}
                  image={`${IMG_BASE_URL}/${IMG_300}/${item?.poster_path}`}
                  title={item.name}
                />
                <span style={{
                  background: 'rgb(0 0 0 / 35%)',
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                }} />
                <CardContent sx={{ width: '100%', padding: '0px !important', position: 'absolute', bottom: 0, color: 'whitesmoke' }}>
                  <Typography variant="h5" sx={{ textAlign: 'left', fontSize: 17, fontWeight: '500', padding: 1 }}>
                    {item?.title}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0px 8px 8px 8px' }}>
                    <Typography variant="subtitle2" sx={{ textAlign: 'left', fontSize: 13, fontWeight: '500', background: blue[600], padding: '6px 8px 4px 8px', borderRadius: 5, width: 'auto' }}>
                      {item?.release_date ? item?.release_date?.split('-')[0] : 'N/A'}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ textAlign: 'left', fontSize: 13, fontWeight: '500', background: yellow[900], padding: '6px 8px 4px 8px', borderRadius: 5, width: 'auto' }}>
                      {`${item?.vote_average?.toFixed(1)}/10`}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Link>
          )) : (
            [...new Array(20)]?.map((_, index) => (
              <Card sx={{ minWidth: 220, maxWidth: 220, margin: 1, boxShadow: 'none', position: 'relative' }} key={index}>
                <Skeleton
                  sx={{ bgcolor: 'grey.500' }}
                  variant='rectangular'
                  width={220}
                  height={320}
                  key={index}
                />
              </Card>
            ))
          )}
        </Grid>
      </Grid>
      {movies?.results?.length ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Pagination count={movies?.total_pages} page={page} variant='outlined' shape="rounded" sx={{
            '& button': {
              background: 'rgba(255, 255, 255, .1)',
              color: 'whitesmoke'
            },
            '& button.Mui-selected': {
              background: 'whitesmoke',
              color: '#000'
            },
            '& div': {
              color: '#fff'
            }
          }} onChange={onPaginationChange} />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Skeleton
            sx={{ bgcolor: 'grey.500' }}
            variant='rectangular'
            width='40%'
          />
        </Box>
      )}
      <Footer />
    </>
  );
}

export default Home;
