import {
    configureStore
} from '@reduxjs/toolkit'
import createSagaMiddleware from "@redux-saga/core";
import reducer from '../reducers/index'
import rootSaga from '../sagas/index'

const middleware = createSagaMiddleware();

const store = configureStore({
    reducer: reducer,
    middleware: () => [middleware],
    devTools: process.env.NODE_ENV === 'development'
})

middleware.run(rootSaga);

export default store;