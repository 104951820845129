import axios from "axios";
import {
    BASE_URL,
    HEADERS,
    VERSION
} from "./constants";


const axiosHttp = axios.create({
    baseURL: `${BASE_URL}/${VERSION}`,
});

axiosHttp.interceptors.request.use(
    (config) => {
        return {
            ...config,
            headers: HEADERS,
        };
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosHttp.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            console.log('unauthorized')
        }
        return Promise.reject(error);
    }
);

export default axiosHttp;