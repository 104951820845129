import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import NotFound from './movies/not_found';
import Home from './movies/home';
import MovieDetail from './movies/movie_detail';
import Searches from './movies/search';
import Trending from './movies/trending';
import ShowDetail from './movies/show_detail';

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/genres/:genre' element={<Home />} />
          <Route path='/movies' element={<Trending type='movie' />} />
          <Route path='/tv' element={<Trending type='tv' />} />
          <Route path='/trending' element={<Trending type='all' />} />
          <Route path='/movie/:id' element={<MovieDetail type='movie' />} />
          <Route path='/tv/:id' element={<ShowDetail type='tv' />} />
          <Route path='/search' element={<Searches />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
