import React from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import StarIcon from "@mui/icons-material/Star";
import styled from "styled-components";
import Header from "../header";
import Footer from "../footer";
import Sidebar from "../sidebar";
import getMoviesDetails from "../api/movies/movieDetails";
import { IMG_300, IMG_BASE_URL, TV_URL } from "../constants";
import { Link } from "react-router-dom";
import { get_movie_details } from "../actions/movies/actions";
import { InputLabel, Skeleton } from "@mui/material";

const PaperStyled = styled(Paper)((prop) => ({
  background: "rgba(255, 255, 255, .1) !important",
  margin: "8px 8px 32px 8px",
}));
const BoxStyled = styled(Box)((prop) => ({
  display: "flex",
  marginTop: 8,
  "& p:first-child": {
    width: 150,
  },
  "& p:last-child": {
    width: 400,
  },
}));

const ShowDetail = ({ type }) => {
  const movieDetail = useSelector((state) => state.movieDetail.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [season, setSeason] = React.useState(1);
  const [episode, setEpisode] = React.useState(1);

  React.useEffect(() => {
    async function fetchData() {
      const payload = {
        id,
        type,
      };
      const response = await getMoviesDetails(payload);
      if (response) {
        dispatch(get_movie_details(response?.data));
      } else {
        navigate("/");
      }
    }
    fetchData();
  }, [dispatch, id, navigate, type]);

  return (
    <>
      <Header />
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "stretch",
            justifyContent: "center",
            flexWrap: "wrap",
            flex: 1,
          }}
        >
          <Grid item xs={12} lg={8}>
            <PaperStyled>
              <Box sx={{ width: "100%" }} className="videoPlayerContainer">
                <iframe
                  src={`${TV_URL}/${id}/${season}/${episode}`}
                  style={{ width: "100%", height: 500 }}
                  title={movieDetail?.name}
                  frameBorder="0"
                  allowFullScreen
                  referrerPolicy="origin"
                ></iframe>
              </Box>
              <Box>
                <Typography
                  variant="h6"
                  color="whitesmoke"
                  align="center"
                  padding='5px 10px 10px 10px'
                >
                  {movieDetail?.name} | SS{season} EP{episode}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: '#394149',
                  padding: 2,
                }}
              >
                <InputLabel sx={{ color: "whitesmoke", margin: 1 }}>
                  Season
                </InputLabel>
                <Select
                  labelId="season-label"
                  id="season-select"
                  value={season}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={(e) => setSeason(e?.target?.value)}
                  sx={{
                    width: "100px",
                    color: "whitesmoke",
                    background: "#575d64",
                    "& .MuiSelect-select": { padding: 1 },
                    "& svg": { color: "whitesmoke" },
                  }}
                  className="seasonDropdown"
                >
                  {new Array(movieDetail?.number_of_seasons)
                    .fill(0)
                    .map((_, index) => (
                      <MenuItem value={index + 1} key={index}>{index + 1}</MenuItem>
                    ))}
                </Select>
                <InputLabel sx={{ color: "whitesmoke", margin: 1 }}>
                  Episodes
                </InputLabel>
                <Select
                  labelId="episode-label"
                  id="episode-select"
                  value={episode}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={(e) => setEpisode(e?.target?.value)}
                  sx={{
                    width: "100px",
                    color: "whitesmoke",
                    background: "#575d64",
                    "& .MuiSelect-select": { padding: 1 },
                    "& svg": { color: "whitesmoke" },
                  }}
                  className="episodeDropdown"
                >
                  {new Array(movieDetail?.number_of_episodes)
                    .fill(0)
                    .map((_, index) => (
                      <MenuItem value={index + 1} key={index}>{index + 1}</MenuItem>
                    ))}
                </Select>
              </Box>
            </PaperStyled>
            <PaperStyled sx={{ borderRadius: 5, padding: 2 }}>
              {movieDetail && (
                <Box
                  sx={{ display: "flex", color: "whitesmoke" }}
                  className="infoCard"
                >
                  <Box sx={{ padding: 1 }}>
                    {movieDetail?.poster_path ? (
                      <img
                        src={`${IMG_BASE_URL}/${IMG_300}/${movieDetail?.poster_path}`}
                        alt={movieDetail.name}
                        style={{ height: "auto", borderRadius: 10 }}
                      />
                    ) : (
                      <Skeleton
                        sx={{ bgcolor: 'grey.500', borderRadius: 2.5, margin: '0 auto' }}
                        variant='rectangular'
                        width={300}
                        height={450}
                        className="imagebackup"
                      />
                    )}
                  </Box>
                  <Box sx={{ padding: 1 }}>
                    <Typography variant="h4">{movieDetail?.name}</Typography>
                    <Box display="flex" justifyContent="space-between">
                      <Typography>
                        S{movieDetail?.number_of_seasons}, E
                        {movieDetail?.number_of_episodes}
                      </Typography>
                      <Typography
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <StarIcon
                          sx={{ color: "rgb(250, 175, 0)", fontSize: 18 }}
                        />{" "}
                        {movieDetail?.vote_average?.toFixed(1)}
                      </Typography>
                      <Typography>
                        ({movieDetail?.vote_count} reviews)
                      </Typography>
                    </Box>
                    <Typography
                      sx={{ marginTop: 1, marginBottom: 2, fontSize: 14 }}
                    >
                      {movieDetail?.overview}
                    </Typography>
                    <BoxStyled>
                      <Typography>Type:</Typography>
                      <Typography>TV</Typography>
                    </BoxStyled>
                    <BoxStyled>
                      <Typography>Season(s):</Typography>
                      <Typography>{movieDetail?.number_of_seasons}</Typography>
                    </BoxStyled>
                    <BoxStyled>
                      <Typography>Episode(s):</Typography>
                      <Typography>{movieDetail?.number_of_episodes}</Typography>
                    </BoxStyled>
                    <BoxStyled>
                      <Typography>Release Date:</Typography>
                      <Typography>{movieDetail?.first_air_date}</Typography>
                    </BoxStyled>
                    <BoxStyled>
                      <Typography>Genres:</Typography>
                      <Typography>
                        {movieDetail?.genres?.map((item, index) => (
                          <React.Fragment key={index}>
                            <Link
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                              to={`/genres/${item?.id}`}
                            >
                              {item?.name}
                            </Link>
                            {index + 1 !== movieDetail?.genres?.length
                              ? ", "
                              : ""}
                          </React.Fragment>
                        ))}
                      </Typography>
                    </BoxStyled>
                    <BoxStyled>
                      <Typography>Production:</Typography>
                      <Typography>
                        {movieDetail?.production_companies?.map(
                          (item, index) => (
                            <React.Fragment key={index}>
                              {item?.name}
                              {index + 1 !==
                                movieDetail?.production_companies?.length
                                ? ", "
                                : ""}
                            </React.Fragment>
                          )
                        )}
                      </Typography>
                    </BoxStyled>
                    <BoxStyled>
                      <Typography>Country:</Typography>
                      <Typography>
                        {movieDetail?.production_countries?.map(
                          (item, index) => (
                            <React.Fragment key={index}>
                              {item?.name}
                              {index + 1 !==
                                movieDetail?.production_countries?.length
                                ? ", "
                                : ""}
                            </React.Fragment>
                          )
                        )}
                      </Typography>
                    </BoxStyled>
                  </Box>
                </Box>
              )}
            </PaperStyled>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Sidebar />
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default ShowDetail;
