import { put, takeLatest } from "redux-saga/effects";
import { GET_MOVIES } from "../../actions/movies/types";
import DiscoverMovies from "../../api/movies/movies";
import { movies_list } from "../../actions/movies/actions";


function* getMoviesData() {
  try {
    // const response = DiscoverMovies()
    // yield put(movies_list_success(response.data));
  } catch (error) {
    // yield put(movies_list_failed(error))
  }
}

export function* getMovies() {
  yield takeLatest(GET_MOVIES, getMoviesData)
}