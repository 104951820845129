import React from 'react'
import { useParams } from 'react-router';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import StarIcon from '@mui/icons-material/Star';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import getPopularMovies from '../api/movies/popular';
import { popular_movies } from '../actions/movies/actions';
import { Link } from 'react-router-dom';
import { IMG_200, IMG_BASE_URL } from '../constants';
import './style.css'

const PaperStyled = styled(Paper)((prop) => ({
  background: 'rgba(255, 255, 255, .1) !important',
  margin: '0px 0px 32px 16px',
  color: 'whitesmoke !important',
  borderRadius: '20px !important',
}))

const Sidebar = () => {
  const popular = useSelector(state => state.popular.data)
  const { id } = useParams()
  const dispatch = useDispatch();

  React.useEffect(() => {
    async function fetchData() {
      const response = await getPopularMovies();
      dispatch(popular_movies(response?.data));
    }
    fetchData();

  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <PaperStyled className='sidebarPaper'>
        <Typography variant='h6' align='center' sx={{ padding: 2 }}>You Might Also Like</Typography>
        <Box>
          {popular?.results?.length ? popular?.results?.filter(item => item.id !== parseInt(id)).map((item, index) => (
            <Link to={`/movie/${item?.id}`} key={index} className='sidebarLink'>
              <Box className='cover'>
                <img src={`${IMG_BASE_URL}/${IMG_200}${item?.poster_path}`} alt={item?.title} />
              </Box>
              <Box className='info'>
                <Typography>{item?.title}</Typography>
                <Box className='otherInfo'>
                  <Typography sx={{ display: 'flex', alignItems: 'center' }}><StarIcon sx={{ color: 'rgb(250, 175, 0)', fontSize: 18 }} /> {item?.vote_average?.toFixed(1)}</Typography>
                  <span>Movie</span><span>{item?.release_date?.split('-')[0]}</span>
                </Box>
              </Box>
            </Link>
          )) : (
            [...new Array(20)]?.map((_, index) => (
              <Box sx={{
                display: 'flex'
              }} key={index}>
                <Box sx={{
                  display: 'flex',
                  padding: '10px 20px',
                }}>
                  <Skeleton
                    sx={{ bgcolor: 'grey.600' }}
                    variant='rectangular'
                    width={60}
                    height={85}
                  />
                </Box>
                <Box className='info'>
                  <Typography><Skeleton /></Typography>
                  <Box className='otherInfo'>
                    <Typography sx={{ display: 'flex', alignItems: 'center' }}><Skeleton variant='circular' width={18} height={18} /></Typography>
                    <Typography><Skeleton width={80} /></Typography>
                    <Typography><Skeleton width={80} /></Typography>
                  </Box>
                </Box>
              </Box>
            ))
          )}
        </Box>
      </PaperStyled>
    </Grid>
  )
}

export default Sidebar