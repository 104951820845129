import {
  GET_POPULAR
} from "../../actions/movies/types";

const initialState = {
  fetching: 'pending',
  data: [],
}

const PopularMovieReducer = (state = initialState, {
  type,
  payload
}) => {
  switch (type) {
    case GET_POPULAR: {
      return {
        ...state,
        fetching: 'completed',
        data: payload
      }
    }

    default:
      return state;
  }
}

export default PopularMovieReducer;