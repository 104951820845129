import {
    GET_GENRES,
    GET_MOVIES,
    GET_MOVIE_DETAILS,
    GET_POPULAR
} from './types'

export const movies_list = (payload) => {
    return {
        type: GET_MOVIES,
        payload: payload
    }
}

export const genre_list = (payload) => {
    return {
        type: GET_GENRES,
        payload: payload
    }
}

export const popular_movies = (payload) => {
    return {
        type: GET_POPULAR,
        payload: payload
    }
}
export const get_movie_details = (payload) => {
    return {
        type: GET_MOVIE_DETAILS,
        payload: payload
    }
}