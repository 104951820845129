import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Footer from "../footer";
import Header from "../header";

const NotFound = () => {
  return (
    <>
      <Header />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ padding: 2, display: 'flex', justifyContent: 'center' }}>
            <h1>404</h1>
          </Box>
        </Grid>
      </Grid >
      <Footer />
    </>
  );
};

export default NotFound;