import {
  BASE_URL,
  VERSION
} from "../../constants";
import axiosHttp from "../../utils";

const SearchMovies = async (payload) => {
  const {
    include_adult,
    query,
    page,
    type,
  } = payload
  try {
    let url = `${BASE_URL}/${VERSION}/search/${type}?`;
    url += `language=en-US&`;
    if (query) {
      url += `query=${query}&`;
    }
    if (include_adult) {
      url += `include_adult=${include_adult}&`;
    }
    if (page) {
      url += `page=${page}&`;
    }

    const response = await axiosHttp.get(url).then(resp => resp.data)

    return response
  } catch (error) {
    console.log('Error getting data from API')
  }
}

export default SearchMovies;