import axiosHttp from "../../utils";

const getMoviesDetails = ({ id, type }) => {
  try {
    const response = axiosHttp.get(`/${type}/${id}?language=en-US`);
    if (!response) {
      throw new Error('Error')
    }
    return response;

  } catch (error) {
    // error
  }
}

export default getMoviesDetails;