import {
  BASE_URL,
  VERSION
} from "../../constants";
import axiosHttp from "../../utils";

const TrendingMovies = async (payload) => {
  const {
    time_window,
    type,
    page
  } = payload
  try {
    let url = `${BASE_URL}/${VERSION}/trending/${type}/${time_window}?`;
    url += `language=en-US&`;
    if (page) {
      url += `page=${page}`;
    }

    const response = await axiosHttp.get(url).then(resp => resp.data)

    return response
  } catch (error) {
    console.log('Error getting data from API')
  }
}

export default TrendingMovies;