import React from 'react'
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import getGenres from '../api/movies/getGenre';
import { useDispatch, useSelector } from 'react-redux';
import { genre_list } from '../actions/movies/actions';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const LinkStyled = styled(Link)((prop) => ({
  textDecoration: 'none',
  color: 'whitesmoke',
  cursor: 'pointer'
}))

const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const genres = useSelector(state => state.genres.data);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname])


  React.useEffect(() => {
    async function fetchData() {
      const response = await getGenres();
      dispatch(genre_list(response?.genres));
    }
    fetchData();

  }, [dispatch]);

  return (
    <Grid container spacing={2} marginTop='8px'>
      <Grid item xs={12} sx={{
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'center',
        flexWrap: 'wrap',
        flex: 1,
      }} className='headerMenu'>
        <MenuItem onClick={() => navigate('/')} className={pathname === '/' ? 'active' : ''}><LinkStyled>Home</LinkStyled></MenuItem>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{
            color: 'whitesmoke',
            textTransform: 'capitalize',
            fontSize: 'inherit'
          }}
          className={pathname.split('/')[pathname.split('/').length - 2] === 'genres' ? 'active' : ''}
        >
          Genres
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          sx={{
            '& .MuiPaper-root': {
              background: '#394149',
              height: 300
            }
          }}
          className='parentMenu'
        >
          {genres?.map((item) => (
            <MenuItem onClick={() => navigate(`/genres/${item.id}`)} key={item?.id} className={parseInt(pathname.split('/')[pathname.split('/').length - 1]) === parseInt(item?.id) ? 'active' : ''}><LinkStyled>{item?.name}</LinkStyled></MenuItem>
          ))}
        </Menu>
        <MenuItem onClick={() => navigate('/movies')} className={pathname === '/movies' ? 'active' : ''}><LinkStyled>Movies</LinkStyled></MenuItem>
        <MenuItem onClick={() => navigate('/tv')} className={pathname === '/tv' ? 'active' : ''}><LinkStyled>TV Shows</LinkStyled></MenuItem>
        <MenuItem onClick={() => navigate('/trending')} className={pathname === '/trending' ? 'active' : ''}><LinkStyled>Trending</LinkStyled></MenuItem>
      </Grid>
    </Grid>
  )
}

export default Header