import axiosHttp from "../../utils";

const getPopularMovies = () => {
    try {
        const response = axiosHttp.get('/movie/popular?language=en-US&page=1');
        return response;

    } catch (error) {
        //handle error here...
    }
}

export default getPopularMovies;