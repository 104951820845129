import {
    BASE_URL,
    VERSION
} from "../../constants";
import axiosHttp from "../../utils";

const getGenres = async () => {
    try {
        const response = await axiosHttp.get(`${BASE_URL}/${VERSION}/genre/movie/list?language=en`).then(resp => resp.data)

        return response
    } catch (error) {
        console.log('Error getting data from API')
    }
}

export default getGenres;