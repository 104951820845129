import React from 'react'
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import StarIcon from '@mui/icons-material/Star';
import styled from 'styled-components';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import getMoviesDetails from '../api/movies/movieDetails';
import { IMG_300, IMG_BASE_URL, MOVIE_URL } from '../constants';
import { Link } from 'react-router-dom';
import { get_movie_details } from '../actions/movies/actions';
import { Skeleton } from '@mui/material';

const PaperStyled = styled(Paper)((prop) => ({
  background: 'rgba(255, 255, 255, .1) !important',
  margin: '8px 8px 32px 8px',
}))
const BoxStyled = styled(Box)((prop) => ({
  display: 'flex',
  marginTop: 8,
  '& p:first-child': {
    width: 150
  },
  '& p:last-child': {
    width: 400
  }
}))

const MovieDetail = ({ type }) => {
  const movieDetail = useSelector(state => state.movieDetail.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams()

  React.useEffect(() => {
    async function fetchData() {
      const payload = {
        id,
        type,
      }
      const response = await getMoviesDetails(payload);
      if (response) {
        dispatch(get_movie_details(response?.data));
      } else {
        navigate('/');
      }
    }
    fetchData();
  }, [dispatch, id, navigate, type]);

  return (
    <>
      <Header />
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{
          display: 'flex',
          alignItems: 'stretch',
          justifyContent: 'center',
          flexWrap: 'wrap',
          flex: 1,
        }}>
          <Grid item xs={12} md={8}>
            <PaperStyled>
              <Box sx={{ width: '100%' }} className='videoPlayerContainer'>
                <iframe src={`${MOVIE_URL}/${id}`} style={{ width: '100%', height: 500 }} title={movieDetail?.title} frameBorder="0" allowFullScreen referrerPolicy="origin"></iframe>
              </Box>
              <Box>
                <Typography variant='h6' color='whitesmoke' align='center' padding='5px 10px 10px 10px'>
                  {movieDetail?.title}
                </Typography>
              </Box>
            </PaperStyled>
            <PaperStyled sx={{ borderRadius: 5, padding: 2 }}>
              {movieDetail && (
                <Box sx={{ display: 'flex', color: 'whitesmoke' }} className='infoCard'>
                  <Box sx={{ padding: 1 }}>
                    {movieDetail?.poster_path ? (
                      <img src={`${IMG_BASE_URL}/${IMG_300}/${movieDetail?.poster_path}`} alt={movieDetail.name} style={{ height: 'auto', borderRadius: 10 }} />
                    ) : (
                      <Skeleton
                        sx={{ bgcolor: 'grey.500', borderRadius: 2.5, margin: '0 auto' }}
                        variant='rectangular'
                        width={300}
                        height={450}
                        className="imagebackup"
                      />
                    )}
                  </Box>
                  <Box sx={{ padding: 1 }}>
                    <Typography variant='h4'>{movieDetail?.title}</Typography>
                    <Box display='flex' justifyContent='space-between'>
                      <Typography>{movieDetail?.runtime} min</Typography>
                      <Typography sx={{ display: 'flex', alignItems: 'center' }}><StarIcon sx={{ color: 'rgb(250, 175, 0)', fontSize: 18 }} /> {movieDetail?.vote_average?.toFixed(1)}</Typography>
                      <Typography>({movieDetail?.vote_count} reviews)</Typography>
                    </Box>
                    <Typography sx={{ marginTop: 1, marginBottom: 2, fontSize: 14 }} >{movieDetail?.overview}</Typography>
                    <BoxStyled>
                      <Typography>Type:</Typography>
                      <Typography>Movie</Typography>
                    </BoxStyled>
                    <BoxStyled>
                      <Typography>Release Date:</Typography>
                      <Typography>{movieDetail?.release_date}</Typography>
                    </BoxStyled>
                    <BoxStyled>
                      <Typography>Genres:</Typography>
                      <Typography>
                        {movieDetail?.genres?.map((item, index) => (
                          <React.Fragment key={index}>
                            <Link style={{
                              textDecoration: 'none',
                              color: 'inherit'
                            }}
                              to={`/genres/${item?.id}`}
                            >
                              {item?.name}
                            </Link >
                            {index + 1 !== movieDetail?.genres?.length ? ', ' : ''}
                          </React.Fragment>
                        ))}
                      </Typography>
                    </BoxStyled>
                    <BoxStyled>
                      <Typography>Production:</Typography>
                      <Typography>
                        {movieDetail?.production_companies?.map((item, index) => (
                          <React.Fragment key={index}>
                            {item?.name}
                            {index + 1 !== movieDetail?.production_companies?.length ? ', ' : ''}
                          </React.Fragment>
                        ))}
                      </Typography>
                    </BoxStyled>
                    <BoxStyled>
                      <Typography>Country:</Typography>
                      <Typography>
                        {movieDetail?.production_countries?.map((item, index) => (
                          <React.Fragment key={index}>
                            {item?.name}
                            {index + 1 !== movieDetail?.production_countries?.length ? ', ' : ''}
                          </React.Fragment>
                        ))}
                      </Typography>
                    </BoxStyled>
                    {movieDetail?.budget !== 0 && (
                      <BoxStyled>
                        <Typography>Budget:</Typography>
                        <Typography>
                          {movieDetail?.budget?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Typography>
                      </BoxStyled>
                    )}
                    {movieDetail?.revenue !== 0 && (
                      <BoxStyled>
                        <Typography>Collection:</Typography>
                        <Typography>
                          {movieDetail?.revenue?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Typography>
                      </BoxStyled>
                    )}
                  </Box>
                </Box>
              )}
            </PaperStyled>
          </Grid>
          <Grid item xs={12} md={4}>
            <Sidebar />
          </Grid>
        </Grid>
      </Grid >
      <Footer />
    </>
  );
}

export default MovieDetail;
