import {
    combineReducers
} from "redux";
import MoviesReducer from "./movies";
import GenresReducer from "./movies/genres";
import PopularMovieReducer from './movies/popular'
import MovieDetailsReducer from "./movies/movieDetails";

const reducer = combineReducers({
    movies: MoviesReducer,
    genres: GenresReducer,
    popular: PopularMovieReducer,
    movieDetail: MovieDetailsReducer,
})

export default reducer;