import {
  BASE_URL,
  VERSION
} from "../../constants";
import axiosHttp from "../../utils";

const DiscoverMovies = async (payload) => {
  const {
    genre,
    keyword,
    include_adult,
    include_video,
    page,
    sort_by,
    year,
  } = payload
  try {
    let url = `${BASE_URL}/${VERSION}/discover/movie?`;
    url += `language=en-US&`;
    if (genre) {
      url += `with_genres=${genre}&`;
    }
    if (keyword) {
      url += `with_keywords=${keyword}&`;
    }
    if (include_adult) {
      url += `include_adult=${include_adult}&`;
    }
    if (include_video) {
      url += `include_video=${include_video}&`;
    }
    if (page) {
      url += `page=${page}&`;
    }
    if (sort_by) {
      url += `sort_by=${sort_by}&`;
    }
    if (year) {
      url += `year=${year}&`;
    }

    const response = await axiosHttp.get(url).then(resp => resp.data)

    return response
  } catch (error) {
    console.log('Error getting data from API')
  }
}

export default DiscoverMovies;