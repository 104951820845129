export const BASE_URL = process.env.REACT_APP_BASE_URL
export const VERSION = 3
export const HEADERS = {
    accept: 'application/json',
    Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyZWJhZGM0Y2I0NzA2ZGU1ZTY0NTY3NjcwMjRiYzMzOCIsInN1YiI6IjY1OWUzNjM1OGU4ZDMwMDBlZjAzZWFjZiIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.VCNtPL_KQ9l51J_FcShkV8VQvY4oV70U0S8kUDDuTyU'
}
export const IMG_BASE_URL = 'https://image.tmdb.org/t/p'
export const IMG_300 = 'w300'
export const IMG_200 = 'w200'
export const IMG_ORIGINAL = 'original'
export const MOVIE_URL = 'https://vidsrc.to/embed/movie'
export const TV_URL = 'https://vidsrc.to/embed/tv'