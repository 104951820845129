import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled, alpha } from "@mui/material/styles";
import { movies_list } from "../actions/movies/actions";
import { useDispatch, useSelector } from "react-redux";
import SearchMovies from "../api/movies/searchMovies";
import Header from "../header";
import Footer from "../footer";
import { IMG_300, IMG_BASE_URL } from "../constants";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import "./style.css";
import { blue, green, orange, yellow } from "@mui/material/colors";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const Searches = () => {
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState("");
  const [type, setType] = React.useState("movie");
  const [newSearch, setNewSearch] = React.useState(false);
  const movies = useSelector((state) => state.movies.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    if (search) {
      async function fetchData() {
        const payload = {
          page: page,
          query: search,
          type,
        };
        const response = await SearchMovies(payload);
        dispatch(movies_list(response));
      }
      fetchData();
      setNewSearch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, newSearch, type]);

  const onPaginationChange = (event, page) => {
    setPage(page);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  React.useEffect(() => {
    setSearch(searchParams.get("s"));
    setNewSearch(true);
    setType(searchParams.get("type") ?? "multi");
  }, [searchParams]);

  const onSearch = (e) => {
    setSearch(e?.target?.value);
  };

  const onKeyDownHandler = (e) => {
    if (e?.key === 'Enter') {
      navigate(`/search?s=${e?.target?.value}&type=multi`);
    }
  }

  const onHandleClick = () => {
    if (search) {
      setNewSearch(true);
      navigate(`/search?s=${search}&type=multi`);
    }
  };

  return (
    <>
      <Header />
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{
            margin: "10px 16px 8px 16px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                onChange={onSearch}
                onKeyDown={onKeyDownHandler}
                value={search}
              />
            </Search>
            <Button
              variant="contained"
              sx={{
                marginLeft: 1,
                color: "whitesmoke",
                backgroundColor: "rgba(255, 255, 255, .1)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, .1)",
                },
              }}
              onClick={onHandleClick}
            >
              Search
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "stretch",
            justifyContent: "center",
            flexWrap: "wrap",
            flex: 1,
          }}
        >
          {movies?.results?.length
            ? movies?.results?.map((item, index) => {
              if (item?.media_type === 'person') return null;

              return (
                <Link to={`/${item?.media_type}/${item?.id}`} key={index} className="links">
                  <Card
                    sx={{
                      minWidth: 220,
                      maxWidth: 220,
                      margin: 1,
                      boxShadow: "none",
                      position: "relative",
                    }}
                  >
                    <CardMedia
                      sx={{ height: 320 }}
                      image={`${IMG_BASE_URL}/${IMG_300}/${item?.poster_path}`}
                      title={item.name}
                    />
                    <span
                      style={{
                        background: "rgb(0 0 0 / 35%)",
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: 0,
                      }}
                    />
                    <CardContent
                      sx={{
                        width: "100%",
                        padding: "0px !important",
                        position: "absolute",
                        bottom: 0,
                        color: "whitesmoke",
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          textAlign: "left",
                          fontSize: 17,
                          fontWeight: "500",
                          padding: 1,
                        }}
                      >
                        {item?.title}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "0px 8px 8px 8px",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{
                            textAlign: "left",
                            fontSize: 13,
                            fontWeight: "500",
                            background: blue[600],
                            padding: "6px 8px 4px 8px",
                            borderRadius: 5,
                            width: "auto",
                          }}
                        >
                          {item?.release_date
                            ? item?.release_date?.split("-")[0]
                            : item?.first_air_date ? item?.first_air_date?.split("-")[0] : 'NULL'}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            textAlign: "left",
                            fontSize: 13,
                            fontWeight: "500",
                            background: yellow[900],
                            padding: "6px 8px 4px 8px",
                            borderRadius: 5,
                            width: "auto",
                          }}
                        >
                          {`${item?.vote_average?.toFixed(1)}/10`}
                        </Typography>
                        {item?.media_type && (
                          <Typography
                            variant="subtitle2"
                            sx={{
                              textAlign: "left",
                              fontSize: 13,
                              fontWeight: "500",
                              background:
                                item?.media_type === "tv"
                                  ? orange[500]
                                  : green[500],
                              padding: "6px 8px 4px 8px",
                              borderRadius: 5,
                              width: "auto",
                            }}
                          >
                            {`${item?.media_type?.toUpperCase()}`}
                          </Typography>
                        )}
                      </Box>
                    </CardContent>
                  </Card>
                </Link>
              );
            })
            : [...new Array(20)]?.map((_, index) => (
              <Card
                sx={{
                  minWidth: 220,
                  maxWidth: 220,
                  margin: 1,
                  boxShadow: "none",
                  position: "relative",
                }}
                key={index}
              >
                <Skeleton
                  sx={{ bgcolor: "grey.500" }}
                  variant="rectangular"
                  width={220}
                  height={320}
                  key={index}
                />
              </Card>
            ))}
        </Grid>
      </Grid>

      {movies?.results?.length > 20 &&
        (movies?.results?.length ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Pagination
              count={movies?.total_pages}
              page={page}
              variant="outlined"
              shape="rounded"
              sx={{
                "& button": {
                  background: "rgba(255, 255, 255, .1)",
                  color: "whitesmoke",
                },
                "& button.Mui-selected": {
                  background: "whitesmoke",
                  color: "#000",
                },
                "& div": {
                  color: "#fff",
                },
              }}
              onChange={onPaginationChange}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Skeleton
              sx={{ bgcolor: "grey.500" }}
              variant="rectangular"
              width="40%"
            />
          </Box>
        ))}
      <Footer />
    </>
  );
};

export default Searches;
