import React from 'react'
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { Divider } from '@mui/material'

const Footer = () => {
  return (
    <Grid container spacing={2} marginBottom='8px' marginTop='16px' color='whitesmoke'>
      <Grid item xs={12}>
        <Box sx={{ padding: 2 }} className='footerDiv'>
          <Typography>A2ZMovies is a top free movie streaming website, allowing you to watch movies online without the need for any registration. Boasting a vast database and impressive features, we are confident that A2ZMovies is the unrivaled choice for the best free online movies, making it an essential destination you simply cannot overlook!</Typography>
        </Box>
        <Divider sx={{ bgcolor: '#999' }} variant='middle' />
        <Typography variant='subtitle2' align='center' sx={{ marginTop: 1 }}>&copy; {new Date().getFullYear()} A2ZMovies. All Rights Reserved</Typography>
      </Grid>
    </Grid>
  )
}

export default Footer